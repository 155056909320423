import * as React from "react";

import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import { Button, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

import { styles } from "../styles";
import Nav from "../components/nav";
import { User } from "../classes/user";
import { useAuth } from "../context/newAuth";
import Settings from "../components/settings";
import { COLORS, SECTIONS } from "../constants";
import { Container, Row } from "react-bootstrap";
import { Loading, Notification } from "../support";
import { GlobalContext, NotificationContext } from "..";
import logoTextDark from "../assets/tempo_logo_text_dark.png";
import { locationType, locationsType, newZoneType } from "../types";
import { FetchLocations, FetchZoneStatus, FetchZones } from "../api/syb";
import { addToLocalStorage, getDevOrDepUrl, useCustomState } from "../utils";

function Location({...props}) {
    const auth = useAuth();
    const navigate = useNavigate();

    const fetchZones = FetchZones();
    const fetchLocations = FetchLocations();
    const fetchZoneStatus = FetchZoneStatus();
    const global = React.useContext(GlobalContext);
    const notification = React.useContext(NotificationContext);

    const [section, setSection] = React.useState("");
    const [zones, setZones] = React.useState<newZoneType[]>([]);
    const [locations, setLocations] = useCustomState<locationsType>({selected: 0, fetchedZones: false});

    React.useEffect(() => {
        if (auth?.user instanceof User) {
            const initializeUserWrapper = async () => {
                const sybAccount = auth.user?.obj?.sybAccount;
                if (!sybAccount) {
                    const url = getDevOrDepUrl("aria");
                    if (url) navigate(url);
                    else return;
                } else {
                    let fetchedLocations;
                    const cachedFetchedLocations = localStorage.getItem(sybAccount);
                    if (cachedFetchedLocations) fetchedLocations = JSON.parse(cachedFetchedLocations);

                    if (!fetchedLocations) {
                        global?.setLoading({message: "Loading locations....", load: true});
                        const response = await fetchLocations({first: 1000, id: sybAccount});
                        global?.setLoading({message: "", load: false});
                        if (response) {
                            fetchedLocations = response.data.account.locations.edges.map((location: any) => ({
                                id: location.node.id,
                                name: location.node.name,
                                city: location.node.city,
                                state: location.node.state,
                                address: location.node.address,
                                isoCountry: location.node.isoCountry,
                                postalCode: location.node.postalCode
                            }));
                            addToLocalStorage(sybAccount, JSON.stringify(fetchedLocations));
                        }
                    }

                    const assignedLocations: string[] = auth.user?.obj?.assignedLocation || [];
                    if (assignedLocations.length !== 0) {
                        fetchedLocations = fetchedLocations.filter((location: any) => assignedLocations.includes(location.id));
                    }

                    // console.log("[Location] >> (assignedLocations)", assignedLocations)
                    let employee = false;
                    switch (auth.user?.obj?.clearance || 0) {
                        case 0: 
                            employee = true;
                            break;
                        default: break;
                    }
                    setLocations({locations: fetchedLocations, selected: 0, employee: employee});
                    return;
                }
            }
            initializeUserWrapper();
        }
    }, [auth?.user]);

    React.useEffect(() => {
        if (!locations || !locations.locations || locations.selected === null) return;
        const zone = async () => {
            setLocations({fetchedZones: true})
                const locationId = locations.locations[locations.selected].id;
                addToLocalStorage("currentLocationId", JSON.stringify(locations.locations[locations.selected].id));
                // localStorage.setItem("currentLocationId", JSON.stringify(locations.locations[locations.selected].name))
                
                let zones;
                const cachedZones = localStorage.getItem(locationId);
                if (cachedZones) zones = JSON.parse(cachedZones);
                if (!zones) {
                    global?.setLoading({load: true, message: "Loading zones..."})
                    const response = await fetchZones({first: 1000, id: locationId});
                    global?.setLoading({load: false, message: ""})

                    zones = response.data.location.soundZones.edges;
                    addToLocalStorage(locationId, JSON.stringify(zones));
                    // localStorage[locationId] = JSON.stringify(zones);
                }
                if (!zones) return;
                
                setZones(zones.map((zone: any) => ({
                    id: zone.node.id, 
                    name: zone.node.name,
                    online: zone.node.online, 
                    isPaired: zone.node.isPaired,
                    scheduleId: zone.node.schedule.id
                })));
            }
        if (!locations.fetchedZones) zone();
    }, [locations]);

    return (
        <>
        {/* <Nav /> */}
        <Container fluid className="column width-100 flex height-100 align-center" style={{margin: "100px 0 0 0"}}>
            {/* <Typography fontSize={28} color={COLORS.ORANGE}>ARIA</Typography> */}
            <img src={logoTextDark} style={{height: 100}} />
            <Nav 
                icon={section === SECTIONS.SETTINGS? ClearIcon : PersonIcon}
                onClick={() => section === SECTIONS.SETTINGS? setSection("") : setSection(SECTIONS.SETTINGS)} 
            />
            {section === ""? 
            <Container className="flex column align-center" style={{margin: "30px 0 0 0", width: "90%"}}>
                {locations.locations? 
                <Select 
                    disabled={locations.employee}
                    value={`${locations.selected}`} 
                    onChange={(event: SelectChangeEvent) => 
                        setLocations({
                            selected: parseInt(event.target.value as string),
                            fetchedZones: false,
                        })
                    }
                    style={{
                        ...styles.dropdown,
                        width: 275,
                    }}
                >
                    {locations.locations.map((location: locationType, i: number) =>
                        <MenuItem key={`location-${i}`}  value={`${i}`}>{location.name}</MenuItem>
                    )}
                </Select> : <></>
                }
                <Row className="flex wrap justify-center" style={{margin: "50px 0 0 0"}}>
                    {zones.map((zone: newZoneType, i: number) => 
                        <Button sx={{...styles.zoneButton}} key={`zone-${i}`} onClick={async () => {
                            const zoneStatus = (await fetchZoneStatus({id: zone.id})).data?.soundZone

                            if (zoneStatus?.isPaired) {
                                const url = getDevOrDepUrl("zone");
                                if (url) {
                                    navigate(url, {state: {zone: zone, selectedLocation: locations.locations[locations.selected]}})
                                } else return
                            } 
                            
                            if (!zoneStatus?.online) {
                                notification?.setNotification({value: `${zone.name} is currently offline`, notify: true})
                            } else if (!zoneStatus?.isPaired) {
                                notification?.setNotification({value: `${zone.name} is currently not paired`, notify: true})
                            }
                        }}>{zone.name}</Button>
                    )}
                </Row>
            </Container> : <></>}
            {section === SECTIONS.SETTINGS? 
                <Container className="flex column align-center" style={{margin: "60px 0 0 0", width: "100%"}}>
                    <Settings location={locations.locations? locations.locations[locations.selected] : null}/> 
                </Container>
                : <></>
            }
        </Container>
        <Loading loading={global?.loading} setLoading={global?.setLoading}/>
        <Notification notification={notification?.notification} setNotification={notification?.setNotification} duration={6000}/>
        </>
    )
}

export default Location;
