import { gql } from "@apollo/client";

export const LOAD_SPOTIFY_PLAYLIST = gql`
    mutation MyMutation($playlistUri: String!, $ownerId: ID!, $first: Int = 1000, $market: IsoCountry = US) {
        createSpotifySyncedPlaylist(
            input: {ownerId: $ownerId, playlistUri: $playlistUri}
        ) {
            playlist {
                id
                name
                tracks(first: $first) {
                    edges {
                        node {
                            id
                            name
                            explicit
                            artists {
                                name
                            }
                            album {
                                display {
                                    image {
                                        sizes {
                                            thumbnail
                                        }
                                    }
                                }
                            }
                            isAvailable(market: $market)
                        }
                    }
                }
            }
        }
    }
`

export const PAUSE = gql`
    mutation MyMutation($id: ID!) {
        pause(input: {soundZone: $id}) {
            status
        }
    }
`

export const PLAY = gql`
    mutation MyMutation($id: ID!) {
        play(input: {soundZone: $id}) {
            status
        }
    }
`

export const SKIP = gql`
    mutation MyMutation($id: ID!) {
        skipTrack(input: {soundZone: $id}) {
            status
        }
    }
`

export const PLAY_TRACK = gql`
    mutation MyMutation($immediate: Boolean = true, $soundZones: [ID!]!, $playlistId: ID = "", $sourceTrackIndex: Int!) {
        soundZoneAssignSource(
            input: {soundZones: $soundZones, source: $playlistId, sourceTrackIndex: $sourceTrackIndex, immediate: $immediate}
        ) {
            source {
                ... on Soundtrack {
                    id
                    name
                }
            }
        }
    }
`

export const UPDATE_SCHEDULE = gql`
    mutation MyMutation($id: ID = "", $slots: [SlotInput!] = {rrule: "", start: "", duration: 10, playlistIds: ""}) {
        updateSchedule(
            input: {id: $id, slots: $slots}
        ) {
            updatedAt
        }
    }
`

export const PLAY_FROM = gql`
    mutation MyMutation($soundZone: ID = "", $source: ID = "") {
        setPlayFrom(input: {soundZone: $soundZone, source: $source}) {
            clientMutationId
        }
    }
`

export const LINEAR_PLAY = gql`
    mutation MyMutation($id: ID!) {
        soundZoneSetPlaybackOrder(input: {soundZone: $id, playbackOrder: LINEAR}) {
            status
        }
    }
`

export const SET_VOLUME = gql`
    mutation MyMutation($id: ID!, $volume: Volume!) {
        setVolume(input: {soundZone: $id, volume: $volume}) {
            status
        }
    }
`

export const QUEUE_TRACKS = gql`
    mutation MyMutation($soundZone: ID = "", $tracks: [ID!] = "") {
        soundZoneQueueTracks(input: {soundZone: $soundZone, tracks: $tracks, clearQueuedTracks: true}) {
            status
        }
    }
`

export const CLEAR_QUEUED_TRACKS = gql`
mutation MyMutation($soundZone: ID = "") {
    soundZoneClearQueuedTracks(input: {soundZone: $soundZone}) {
        status
    }
}
`