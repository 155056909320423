import * as React from "react";

import { Container } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@mui/material";

import "../assets/utils.css";
import { styles } from "../styles";
import { COLORS } from "../constants";
import { NotificationContext } from "..";
import { loginInfoType } from "../types";
import { Notification } from "../support";
import { useAuth } from "../context/newAuth";
import { checkPassword, login } from "../api/user";
import { FormField, PassFormField } from "../forms/fields";
import logoTextLight from "../assets/tempo_logo_text_light.png";
import { getDevOrDepUrl, useCustomState, validateEmail, validatePassword } from "../utils";


function Login({...props}) {
    const auth = useAuth();
    const notification = React.useContext(NotificationContext);
    const [loginInfo, setLoginInfo] = useCustomState<loginInfoType>({});
    const navigate = useNavigate();

    React.useEffect(() => {
        const loginWrapper = async () => {
            if (loginInfo.loggedIn && !loginInfo.resetPass) {
                if (await auth?.needsPasswordChange()) {
                    setLoginInfo({resetPass: true});
                } else {
                    const url = getDevOrDepUrl("location")
                    if (url) navigate(url);
                    else return;
                }
            }

            if (loginInfo.message) {
                notification?.setNotification({value: loginInfo.message, notify: true});
                setLoginInfo({message: null});
            } else login(auth, loginInfo, setLoginInfo, navigate);
        }
        loginWrapper();
    }, [loginInfo]);

    React.useEffect(() => {
        checkPassword(auth, setLoginInfo);
    }, [auth?.isAuthenticated])

    const validateForm = async () => {
        if (!validateEmail(loginInfo.email)) setLoginInfo({status: 403, emailMessage: "Invalid email"});
        else if (!validatePassword(loginInfo.password)) setLoginInfo({status: 403, passMessage: "Password too short", emailMessage: null});
        else setLoginInfo({status: 200, emailMessage: null, passMessage: null});
    }

    const validateResetForm = async () => {
        if (!validatePassword(loginInfo.password)) setLoginInfo({status: 403, passMessage: "Password too short", emailMessage: null});
        else setLoginInfo({status: 201, emailMessage: null, passMessage: null});
    }

    const validateForgotForm = async () => {
        if (!validateEmail(loginInfo.email)) setLoginInfo({status: 403, emailMessage: "Invalid email", passMessage: null});
        else setLoginInfo({status: 300, emailMessage: null, passMessage: null});
    }

    return (
        <Container fluid className="width-100 column flex height-100 align-center justify-center" style={{backgroundColor: COLORS.BLACK}}>
            {/* <Typography fontSize={36} color={COLORS.ORANGE}>ARIA</Typography> */}
            <img src={logoTextLight} style={{height: 100}} />
            {!loginInfo.resetPass? 
                <>
                <FormField 
                    variant="outlined" 
                    placeholder="Username"
                    value={loginInfo.email}
                    helperText={loginInfo.emailMessage}
                    style={{...styles.formField, backgroundColor: COLORS.WHITE}} 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLoginInfo({email: event.target.value})}
                />
                {!loginInfo.forgotPass?
                    <PassFormField 
                        variant="outlined" 
                        value={loginInfo.password}
                        helperText={loginInfo.passMessage}
                        style={{...styles.formField, backgroundColor: COLORS.WHITE}} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLoginInfo({password: event.target.value})}
                    /> : <></>
                }
        
                <Button sx={{...styles.button, border: `1px solid ${COLORS.WHITE}`}} variant="contained" onClick={() => !loginInfo.forgotPass? validateForm() : validateForgotForm()}>{!loginInfo.forgotPass? "Login" : "Send Reset Email"}</Button>
                {!loginInfo.forgotPass? 
                    <Button 
                        sx={{...styles.button, color: COLORS.LIGHT_GRAY, backgroundColor: COLORS.TRANSPARENT}} 
                        onClick={() => {setLoginInfo({forgotPass: true})}}
                    >
                        Forgot Password
                    </Button> : <></>
                }
                </>
                : 
                <>
                <Typography fontSize={16} color={COLORS.BLACK} style={{marginBottom: 20}}>Make a new password</Typography>
                <PassFormField 
                    variant="outlined" 
                    value={loginInfo.password}
                    style={{...styles.formField}} 
                    helperText={loginInfo.passMessage}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLoginInfo({password: event.target.value})}
                />
        
                <Button sx={{...styles.button}} variant="contained" onClick={validateResetForm}>Update Password</Button>
                </>
            }
            <Notification notification={notification?.notification} setNotification={notification?.setNotification} duration={6000}/>
        </Container>
    )
}

export default Login;
