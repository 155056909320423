import { DAYS } from "../constants";
import { Spotify } from '../api/newSpotify';
import { getSoundZoneMetadata } from '../api/firebase';
import { LoadSpotifyPlaylist, UpdateSYBSchedule } from "../api/syb";
import { ariaScheduleType, ariaSchedulesType, ariaSlotType, ariaVibeType, ariaVibesType, debugInfoType, newZoneType, spotifyPlaylist } from "../types";
import { computeDuration, computeFutureTime, convertMilitary, sleep, timeStringToMinutes, toAriaSchedule, useCustomState } from "../utils";
import { sortSlot } from '../api/aria';

const initializePlaylist = (start: string, end: string) => ({spotifyId: "", spotifyUris: [], start: start, end: end, playlists: []});

export function useAriaSchedule(vibes: ariaVibesType, zone?: newZoneType, setNotification?: CallableFunction, setLoading?: CallableFunction, debugInfo?: debugInfoType): {schedules: ariaSchedulesType, updateSchedules: CallableFunction, schedule: CallableFunction, saved: CallableFunction} {
    const updateSYBSchedule = UpdateSYBSchedule();
    const loadSpotifyPlaylist = LoadSpotifyPlaylist(); 

    const [schedules, setSchedules] = useCustomState<ariaSchedulesType>({
        update: false,
        schedules: DAYS.map((day: any) => ({
            day: day.value,
            breaks: [33, 67],
            ariaSlots: [
                initializePlaylist("09:00", "11:38"),
                initializePlaylist("11:38", "14:22"),
                initializePlaylist("14:22", "17:00")
            ]
        }))
    });

    if (!schedules.initialized) {
        const initializeSchedulesWrapper = async () => {
            if (!zone) return;
            setSchedules({initialized: true});
            const zoneMetaData = await getSoundZoneMetadata(zone.id);

            if (!zoneMetaData?.ariaSchedule) {
                const loadedSchedule = toAriaSchedule(zoneMetaData?.scheduleInfo?.schedules);
                if (loadedSchedule) setSchedules({schedules: loadedSchedule});
            } else {
                console.log("[useAriaSchedule] (zoneMetaData?.ariaSchedule) >>", zoneMetaData?.ariaSchedule)
                setSchedules({...zoneMetaData?.ariaSchedule, save: false});
            }
        }
        initializeSchedulesWrapper();
    }

    const updateSchedules = (activeDay: number | undefined, {...props}) => {
        const {breaks, item, slotIndex, sourceIndex, add, remove, saved, start, end} = props;

        if (typeof activeDay === "number") {
            const updatedSchedule = schedules.schedules[activeDay]
            const n = updatedSchedule.ariaSlots.length;

            // handles slot break points update
            if (breaks) {
                const updatedBreaks = getUpdateBreaks(updatedSchedule.ariaSlots, breaks);
                
                updatedSchedule.breaks = updatedBreaks;
                updatedSchedule.ariaSlots = updateBreakTimes(updatedSchedule, updatedBreaks);
            } 
            
            if (item && typeof slotIndex === "number") {
                const updatedSlot = updatedSchedule.ariaSlots[slotIndex];
                console.log(updatedSlot)
                // handles moving playlists from side bar or another slot
                if (add) {
                    if (updatedSlot.playlists?.find(({spotifyUri}) => spotifyUri === item.spotifyUri)) {
                        if (setNotification) setNotification({value: "Playlist already in slot", notify: true}); 
                        return;
                    } 
                    updatedSchedule.ariaSlots[slotIndex] = addPlaylistToSlot(updatedSlot, item);

                    if (typeof sourceIndex === "number") {
                        const sourceSlot = updatedSchedule.ariaSlots[sourceIndex];
                        updatedSchedule.ariaSlots[sourceIndex] = removePlaylistFromSlot(sourceSlot, item);
                    }
                } 
                // handles removing playlist from slot
                else if (remove) updatedSchedule.ariaSlots[slotIndex] = removePlaylistFromSlot(updatedSlot, item);
            }

            if (start) {
                updatedSchedule.ariaSlots[0].start = start;
                updatedSchedule.ariaSlots = updateBreakTimes(updatedSchedule, updatedSchedule.breaks);
            } 
            if (end) {
                updatedSchedule.ariaSlots[n - 1].end = end;
                updatedSchedule.ariaSlots = updateBreakTimes(updatedSchedule, updatedSchedule.breaks);
            }

            setSchedules({
                update: true,
                schedules: schedules.schedules.map((schedule, i) => i === activeDay? updatedSchedule : schedule)
            });
        } else if (saved) {
            setSchedules({schedules: unEdit(schedules), save: false});
        }
        // if (setNotification) setNotification({value: "Schedule updated!", notify: true});
    }

    const schedule = async (spotify: spotifyPlaylist[], spotifyAPI: Spotify) => {
        if (!zone) return;
        console.log("[useAriaSchedule][schedule] (zone.vibesUpdated) >>", zone.vibesUpdated);
        if (!schedules.update && setNotification && !zone.vibesUpdated.reduce((prev, curr) => prev || curr)) {
            setNotification({value: "Schedule already up to date!", notify: true});
            return;
        }

        const response = await constructSlots(vibes, schedules.sybSlots, zone, spotifyAPI, spotify, schedules.schedules, loadSpotifyPlaylist, setLoading, debugInfo);
        if (response) {
            const [slots, updatedSchedules] = response;
            console.log("[useAriaSchedule][schedule] (slot) >>", slots.filter((slot: any) => slot));

            if (setLoading) setLoading({message: "Updating schedule...", load: true})

            const variables = {id: zone.scheduleId, slots: slots.filter((slot: any) => slot)};
            await updateSYBSchedule({variables: variables})
            
            if (setLoading) setLoading({message: "", load: false});
            setSchedules({sybSlots: slots, update: false, save: true, schedules: updatedSchedules});
            if (setNotification) setNotification({value: "Schedule updated!", notify: true})
        }
        else if (setNotification) setNotification({value: "Failed to update schedule", notify: true});
    }

    const saved = () => {
        updateSchedules(undefined, {saved: true})
    }
    return {schedules, updateSchedules, schedule, saved}
}

/**
 * Computes what time a break point is at given a schedule
 * 
 * @param value of the breakpoint as a percent
 * @param schedule to find breakpoint time of
 * @param type indicates return format, defaults to standard
 * @returns the time of the breakpoint in indicated type
 */
export function getBreakTime(value: number, schedule: ariaScheduleType, type?: "military" | "standard") {
    const start = schedule.ariaSlots[0].start;
    const duration = getScheduleDuration(schedule);

    switch (type) {
        case "military": return computeFutureTime(start, duration*value/100);
        case "standard":
        default: return convertMilitary(computeFutureTime(start, duration*value/100));

    }
}

/**
 * Computes the duration in minutes of an aria schedule
 * 
 * @param schedule the aria schedule to find the duration of
 * @returns the duration in minutes
 */
export function getScheduleDuration(schedule: ariaScheduleType): number {
    const end = schedule.ariaSlots.at(-1)!.end
    const start = schedule.ariaSlots[0].start
    const duration = timeStringToMinutes(end) - timeStringToMinutes(start);

    return duration
}

/*** Helper ***/
async function constructSlots(
    vibes: ariaVibesType,
    currentSlots: any,
    zone: newZoneType, 
    spotifyAPI: Spotify, 
    spotify: spotifyPlaylist[], 
    schedules: ariaScheduleType[], 
    loadSpotifyPlaylist: CallableFunction, 
    
    setLoading?: CallableFunction,
    debugInfo?: debugInfoType,
): Promise<[any, ariaScheduleType[]] | undefined> {
    // console.log("[constructSlots] (currentSlots) >>", currentSlots);
    const slots: any[] = currentSlots?.length === 21? currentSlots : Array(21).fill(undefined);

    const loadedPlaylists: spotifyPlaylist[] = [];
    for (let i = 0; i < schedules.length; i++) {
        const schedule = schedules[i];
        const scheduleVibes = vibes.datasets[i];

        for (let j = 0; j < schedule.ariaSlots.length; j++) {
            const k = 3*i + j;
            const ariaSlot = schedule.ariaSlots[j];

            if (!ariaSlot.edited && !zone.vibesUpdated[i]) continue;

            let sybPlaylistId: string[];
            if (ariaSlot.playlistsEdited || !slots[k] || zone.vibesUpdated[i]) {
                console.log("[constructSlots] updating (ariaSlot) >>", ariaSlot);
                
                const aiPlaylistTracks = [];
                for (const slotPlaylist of ariaSlot.playlists) {
                    let spotifyPlaylist;
    
                    spotifyPlaylist = spotify.find(playlist => playlist.uri === slotPlaylist.spotifyUri);
                    if (!spotifyPlaylist || !spotifyPlaylist.tracks) {
                        spotifyPlaylist = loadedPlaylists.find(playlist => playlist.uri === slotPlaylist.spotifyUri);
                        
                        if (!spotifyPlaylist) {
                            if (setLoading) setLoading({message: `Loading ${slotPlaylist.name}...`, load: true});
                            spotifyPlaylist = await spotifyAPI.loadPlaylist(slotPlaylist.spotifyUri.split(":")[2]);
                            if (spotifyPlaylist) loadedPlaylists.push(spotifyPlaylist);
                            if (setLoading) setLoading({message: "", load: false});
                        }
                    }
    
                    if (spotifyPlaylist && spotifyPlaylist.tracks) aiPlaylistTracks.push(...spotifyPlaylist.tracks);
                }
                if (!aiPlaylistTracks.length) {
                    slots[k] = null;
                    continue;
                }

                if (setLoading) setLoading({message: `Sorting ${DAYS[i].name} AI playlist...`, load: true})
                // sort aiPlaylistTracks
                const slotVibes = getSlotVibes(schedule, j, scheduleVibes);
                const sortedAIPlaylistTracksResponse = await sortSlot(aiPlaylistTracks, slotVibes, debugInfo);
                
                let sortedAIPlaylistTracks = null;
                if (sortedAIPlaylistTracksResponse.ok) {
                    sortedAIPlaylistTracks = (await sortedAIPlaylistTracksResponse.json()).sortedTracks;
                    console.log("[scheduleHooks][constructSlots] (sortedAIPlaylistTracks) >>", sortedAIPlaylistTracks);
                } else {
                    if (setLoading) setLoading({message: "", load: false});
                    return;
                }

                // creating spotify ai playlist
                if (setLoading) setLoading({message: `Creating ${DAYS[i].name} AI playlist...`, load: true})
                const response = await spotifyAPI.createPlaylist(sortedAIPlaylistTracks? sortedAIPlaylistTracks : aiPlaylistTracks);
                
                if (!response) {
                    if (setLoading) setLoading({message: "", load: false});
                    return;
                }
                const {spotifyUri} = response;
                schedules[i].ariaSlots[j].edited = false;
                schedules[i].ariaSlots[j].playlistsEdited = false;
                schedules[i].ariaSlots[j].spotifyId = spotifyUri.split(":")[2];
                
                await sleep(5000);
                
                // loading spotify playlist into syb
                if (setLoading) setLoading({message: `Loading ${DAYS[i].name} AI playlist...`, load: true})
                sybPlaylistId = (await loadSpotifyPlaylist({variables: {ownerId: zone.id, playlistUri: spotifyUri}})).data?.createSpotifySyncedPlaylist?.playlist?.id;
                if (setLoading) setLoading({message: "", load: false})
            } else sybPlaylistId = slots[k].playlistIds[0];

            // syb slot
            const slot = {
                playlistIds: [sybPlaylistId],
                start: `${ariaSlot.start.replace(":","")}00`,
                rrule: `FREQ=WEEKLY;BYDAY=${DAYS[i].value}`,
                duration: 60000*computeDuration(ariaSlot.start, ariaSlot.end),
            }

            slots[k] = slot;
        }
    }

    return [slots, schedules];
}

function addVibeToSlot(i: number, x: number, scheduleVibes: ariaVibeType, slotVibes: ariaVibeType) {
    const mood = scheduleVibes.mood[i];
    const energy = scheduleVibes.energy[i];
    
    
    if (i === 0) {
        slotVibes.mood.push({x: x, y: mood.y});
        slotVibes.energy.push({x: x, y: energy.y})
    } else {
        const prev_mood = scheduleVibes.mood[i - 1];
        const prev_energy = scheduleVibes.energy[i - 1];

        slotVibes.mood.push(
            {x: x, y: ((mood.y - prev_mood.y) / (mood.x - prev_mood.x))*(x - prev_mood.x) + prev_mood.y}
        );
        slotVibes.energy.push(
            {x: x, y: ((energy.y - prev_energy.y) / (energy.x - prev_energy.x))*(x - prev_energy.x) + prev_energy.y}
        );
    }

    return slotVibes;
}

function getSlotVibes(schedule: ariaScheduleType, slotIndex: number, scheduleVibes: ariaVibeType): ariaVibeType {
    const start = (slotIndex === 0? 0 : slotIndex === 1? schedule.breaks[0] : schedule.breaks[1]) / 100;
    const end = (slotIndex === 0? schedule.breaks[0] : slotIndex === 1? schedule.breaks[1] : 100) / 100;

    let slotVibes: ariaVibeType = {energy: [], mood: []};
    console.log("[scheduleHooks][getSlotVibes] (start, end) >>", start, end);

    for (let i = 0; i < scheduleVibes.energy.length; i++) {
        const x = scheduleVibes.energy[i].x;
        const next_x = i === scheduleVibes.energy.length - 1? undefined : scheduleVibes.energy[i + 1].x;

        console.log("[scheduleHooks][getSlotVibes] (x, next_x) >>", x, next_x);
        
        if (x >= start && x <= end) {
            if (slotVibes.energy.length === 0) {
                slotVibes = addVibeToSlot(i, start, scheduleVibes, slotVibes);
            }
            
            // add breakpoint
            slotVibes.mood.push(scheduleVibes.mood[i]);
            slotVibes.energy.push(scheduleVibes.energy[i]);
        } else if (x > end) {
            // add end
            slotVibes = addVibeToSlot(i, end, scheduleVibes, slotVibes);
            break;
        } else if (next_x !== undefined && x < start && end < next_x) {
            slotVibes = addVibeToSlot(i, start, scheduleVibes, slotVibes);
        }
    }

    console.log("[scheduleHooks][getSlotVibes] (slotVibes) >>", slotVibes);

    return slotVibes
}

function getUpdateBreaks(slots: ariaSlotType[], breaks: number[]): number[] {
    const n = slots.length;
    const end = timeStringToMinutes(slots[n - 1].end);
    const start = timeStringToMinutes(slots[0].start);
    const duration = end - start;

    const min = Math.ceil(3000/duration);
    const max = Math.floor(100*(1 - 30/duration));
    return [Math.max(min, breaks[0]), Math.min(breaks[1], max)];
}

function updateBreakTimes(schedule: ariaScheduleType, breaks: number[]): ariaSlotType[] {
    const slots = schedule.ariaSlots;
    const n = slots.length;

    for (let i = 0; i < n; i++) {
        slots[i].edited = true;
        if (i !== 0) slots[i].start = slots[i - 1].end;
        if (i !== n - 1) slots[i].end = getBreakTime(breaks[i], schedule, "military");
    }
    return slots;
}

function addPlaylistToSlot(slot: ariaSlotType, item: any): ariaSlotType {
    slot.edited = true;
    if (slot.playlists) slot.playlists.push(item);
    else slot.playlists = [item];
    slot.playlistsEdited = true;
    return slot;
}

function removePlaylistFromSlot(slot: ariaSlotType, item: any): ariaSlotType {
    slot.edited = true;
    slot.playlistsEdited = true;
    slot.playlists = slot.playlists.filter(({spotifyUri}) => spotifyUri !== item.spotifyUri);
    return slot;
}

function unEdit(schedules: ariaSchedulesType): ariaScheduleType[] {
    const n = schedules.schedules.length;

    for (let i = 0; i < n; i++) {
        const schedule = schedules.schedules[i];
        for (let j = 0; j < schedule.ariaSlots.length; j++) {
            const ariaSlot = schedule.ariaSlots[j];
            
            ariaSlot.edited = false;
            ariaSlot.playlistsEdited = false;
            schedule.ariaSlots[j] = ariaSlot;
        }
        schedules.schedules[i] = schedule
    }

    return schedules.schedules;
}
