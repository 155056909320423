import * as React from "react";

import { Spotify } from "./api/newSpotify";
import { API, DAYS, REGEX } from "./constants";
import { newPlaylistDataType, spotifyPlaylist, sybPlaylist, newZoneType, newPlayFromType, LoadingContextType, NotificationContextType, spotifyTrackType, newPlaylistsType, sybScheduleType, ariaSchedulesType, sybTrackType, ariaScheduleType, ariaSlotType, sybSlotType } from "./types";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export function nullify(obj: any) {
    return JSON.parse(JSON.stringify(obj))
}

export function useCustomState<T>(initialState: any): [T, CallableFunction] {
    const [state, setState] = React.useState(initialState);
    const setCustomSate = (newState: any) => {
        setState((prevState: any) => ({...prevState, ...newState}))
    };
    
    return [state, setCustomSate];
}

export async function newPost(url: RequestInfo | URL, body: any, headers: HeadersInit, other?: any) {
    return await fetch(url, {
        method: API.POST,
        headers: {
            // 'Content-Type': API.APPLICATION_JSON,
            ...headers
        },
        credentials: 'same-origin',
        body: body,
        ...other
    })
}

export async function newGet(url: string, args: Object, headers: HeadersInit) {
    url += "?";
    for (const [arg, value] of Object.entries(args)) {
        url += `${arg}=${value}&`
    }

    return await fetch(url, {
        method: API.GET,
        headers: {
            'Content-Type': API.APPLICATION_JSON,
            ...headers
        },
    })
}

export function validateEmail(email: string | undefined): boolean {
    if (email === undefined) return false;
    return email.toLowerCase().match(REGEX.EMAIL) !== null;
}

export function validatePassword(password: string | undefined): boolean {
    if (password === undefined) return false;
    return password.length >= 6;
}

export function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function validateUrl(url: string) {
    return /^https?:\/\/([\w-]+\.)+\w{2,}(\/.+)?$/.test(url);
}

/**
 * Converts a time string into a number of minutes 
 * 
 * @param time in hh:mm (military time)
 * @returns the total amount of minutes equivalent to the elapsing of hh:mm amount of time
 */
export function timeStringToMinutes(time: string): number {
    const [hours, min] = time.split(":").map(i => parseInt(i));
    // console.log("[utils][timeStringToMinutes] (hours, min) >>", hours, min);

    const totalMin = hours*60 + min;

    return totalMin;
}

/**
 * Computes an interval's duration given its start and end times
 * 
 * @param start time in hh:mm (military time)
 * @param end time in hh:mm (military time)
 * @returns the duration of the interval [start, end] in minutes
 */
export function computeDuration(start: string | null, end: string | null): number {
    if (!start || !end) return 0;
    const startMin = timeStringToMinutes(start);
    const endMin = timeStringToMinutes(end);

    return endMin - startMin;
}

/**
 * Computes the end time given a start and end times
 * 
 * @param start time in hh:mm (military time)
 * @param duration time until end in minutes
 * @returns the end time in military time format
 */
export function computeFutureTime(start: string, duration: number) {
    let min = timeStringToMinutes(start) + duration;
    // console.log("[utils][computeFutureTime] (min)[1] >>", min);

    const hour = Math.floor(min / 60);
    min = Math.round(min % 60);
    return `${hour < 10? `0${hour}` : hour}:${min < 10? `0${min}` : min}`;
}

export function convertMilitary(time: string) {
    const [hour, min] = time.split(":").map(t => parseInt(t));
    const state = hour >= 12? "PM" : "AM";
    return `${hour > 12? hour - 12 : hour}:${min < 10? `0${min}` : min} ${state}`;
}

export function msToTimeString(ms: number) {
    const sec = Math.floor(ms / 1000) % 60;
    const min = Math.floor(ms / 1000 / 60);

    return `${min}:${sec < 10? `0${sec}` : sec}`;
}

/**
 * Loads all the relevant spotify needed for each stored playlist in the sidebar. 
 * Invariant: # spotify playlists === # syb playlists
 * 
 * @param zoneMetaData contains the selected zone's sidebar metadata
 * @returns an object containing all the relevant syb and spotify metadata for the sidebar and schedule playlists
 */
export function initializeSidebarPlaylists(zoneMetaData: any): newPlaylistDataType {
    const sidebarPlaylists: newPlaylistDataType = {
        syb: [],
        spotify: []
    };

    let loadedSidebarPlaylists;

    if (!zoneMetaData?.playlists) {
        loadedSidebarPlaylists = zoneMetaData?.spotify?.playlists || [];
    } else {
        loadedSidebarPlaylists = zoneMetaData?.playlists?.sidebar?.spotify || [];
    }

    let start = new Date().getTime();
    /*** Loading sidebar playlists ***/
    for (const playlist of loadedSidebarPlaylists) {
        if (!playlist.uri && !playlist.id) continue

        sidebarPlaylists.spotify.push({
            // ...playlist,
            name: playlist.name, 
            cover:  playlist.cover?  playlist.cover : playlist.imgSrc,
            uri: playlist.uri? playlist.uri : `spotify:playlist:${playlist.id}`,
        })
        sidebarPlaylists.syb.push({name: playlist.name, id: "", tracks: []});
    }

    console.log(`[initializeSidebarPlaylists] it took ${new Date().getTime() - start} ms to load playlists data`);
    return sidebarPlaylists
}

/**
 * Loads all the relevant syb metadata needed for each stored playlist in the schedule. 
 * Invariant: # spotify playlists === # syb playlists
 * 
 * @param zoneMetaData contains the selected zone's schedule metadata
 * @param spotifyAPI used to fetch spotify playlist and associated tracks' meta data
 * @returns an object containing all the relevant syb and spotify metadata for the sidebar and schedule playlists
 */
export async function initializeSchedulePlaylists(zoneMetaData: any, spotifyAPI: Spotify): Promise<newPlaylistDataType> {
    let loadedSchedulePlaylists;

    if (!zoneMetaData?.ariaSchedule) {
        loadedSchedulePlaylists = (zoneMetaData?.scheduleInfo?.schedules || []).reduce(
            (prev: any, schedule: any) => [
                ...prev, 
                ...schedule.buckets.map((bucket: any) => bucket.schedule.id)
            ], []
        ).filter((playlistId: string) => playlistId !== "");
    } else {
        // loadedSchedulePlaylists = (zoneMetaData?.playlists?.schedule?.spotify || []).map(
        //     (playlist: spotifyPlaylist) => playlist?.uri?.split(":")[2]
        // ).filter((id: string) => id !== undefined);
        const ariaSchedule = zoneMetaData.ariaSchedule as ariaSchedulesType;
        
        loadedSchedulePlaylists = (ariaSchedule.schedules || []).reduce(
            (prev: string[], schedule: ariaScheduleType) => [
                ...prev, 
                ...schedule.ariaSlots.map((ariaSlot: ariaSlotType) => ariaSlot.spotifyId)
            ], []
        ).filter((playlistId: string) => playlistId !== "");
    }

    return await loadScheduleSpotifyData(loadedSchedulePlaylists, spotifyAPI);
}

export async function loadScheduleSpotifyData(spotifyIds: string[], spotifyAPI: Spotify): Promise<newPlaylistDataType> {
    let start = new Date().getTime();
    const schedulePlaylists: newPlaylistDataType = {
        syb: [],
        spotify: []
    };

    /*** Loading schedule playlists ***/
    for (const id of spotifyIds) {
        if (!id) continue;
        const response = await spotifyAPI.loadPlaylist(id);
        if (response) {
            schedulePlaylists.syb.push(undefined);
            schedulePlaylists.spotify.push(response);
        }
    }
    
    console.log(`[initializeSchedulePlaylists] it took ${new Date().getTime() - start} ms to load schedule data`);
    return schedulePlaylists
}

/**
 * Loads a playlist's syb meta data.
 * If id is provided then we load directly from syb
 * If uri is provided then we load directly from spotify
 * id is given precedence over uri
 * At least one of id or uri must be provided 
 * 
 * @param loadSpotifyPlaylist either GET_PLAYLIST or LOAD_SPOTIFY_PLAYLIST syb api callers
 * @param zone to fetch the syb playlist information from
 * @param n number of tracks to load from the playlist
 * @param id of the syb playlist to load
 * @param uri of the spotify playlist to load the syb playlist from
 * @param useCache indicates whether to first look for the playlist data in the cache or to load from syb directly, true by default
 * @returns 
 */
export async function loadSYBPlaylistData(loadSpotifyPlaylist: CallableFunction, zone: newZoneType, n: number, id?: string, uri?: string, useCache: boolean = true): Promise<sybPlaylist | undefined> {
    // load syb data
    let response;
    if (id) {
        if (useCache) {
            const storedPlaylist = localStorage.getItem(id)
            if (storedPlaylist) return JSON.parse(storedPlaylist);
        }
        
        response = (await loadSpotifyPlaylist({
            id: id,
            first: n,
        }))
        if (!response) return;

        response = response.data.playlist
    } else if (uri) {
        const storedPlaylist = localStorage.getItem(uri)
        
        if (storedPlaylist) return JSON.parse(storedPlaylist);
    
        response = (await loadSpotifyPlaylist({variables: {
            first: n,
            playlistUri: uri,
            ownerId: zone.id
        }}))
        if (!response) return;
        response = response.data.createSpotifySyncedPlaylist.playlist
    }

    if (response) {
        const sybPlaylist: sybPlaylist = {
            name: response.name,
            id: id? id : response.id,
            tracks: response.tracks.edges.map((edge: any) => ({...edge.node, cover: edge.node.album?.image?.url}))
        }
        
        if (id) addToLocalStorage(id, JSON.stringify(sybPlaylist));
        if (uri) addToLocalStorage(uri, JSON.stringify(sybPlaylist));
        return sybPlaylist;
    }
}

/**
 * Finds a loaded playlist's with a given id
 * 
 * @param id of the playlist to find
 * @param uri of the playlist to find
 * @param name of the playlist to find
 * @param spotify list of loaded spotify playlists
 * @param syb list of loaded syb playlists
 * @returns the loaded playlist with the corresponding id 
 */
export function findPlaylist(id?: string, uri?: string, name?: string, spotify?: spotifyPlaylist[], syb?: sybPlaylist[]) {
    if (syb) {
        return syb.find((playlist: sybPlaylist, i: number) => {
            if (id) return playlist?.id === id;
            else if (name) return playlist?.name === name;
            else if (uri && spotify) return spotify[i].uri === uri;
            return false;
        });
    } else if (spotify) {
        return spotify.find((playlist) => {
            if (uri) return playlist.uri === uri;
            else if (name) return playlist.name === name;
            else if (id) return playlist.uri.split(":")[2] === id;
            return false
        });
    } 
}

/**
 * Finds the schedule playlist that overlaps with today's date and time
 * 
 * @param schedule SYB GET_SCHEDULE response
 * @returns the slot that is scheduled for right now
 */
export function findScheduledSlot(schedule: sybScheduleType): sybSlotType | undefined {
    const today = new Date().getDay();

    let overlappingSlot;
    for (const slot of schedule?.slots || []) {
        const day = slot.rrule.split(";")[1].split("=")[1];
        if (day !== DAYS[today].value) continue;

        if (doesTimeFrameOverlapNow(slot.start, slot.duration)) {
            overlappingSlot = slot;
        }
    }

    return overlappingSlot
}

/**
 * Finds the schedule playlist that overlaps with today's date and time
 * 
 * @param schedule SYB GET_SCHEDULE response
 * @returns the id of the playlist that is scheduled for right now
 */
export function findScheduledPlaylist(schedule: sybScheduleType): string | undefined {
    const overlappingSlot = findScheduledSlot(schedule);
    if (overlappingSlot) {
        return overlappingSlot.playlistIds[0];
    }
}

export function getTimeNow(timeZone: "est" | "pst" | "none" = "est") {
    const currentTime = new Date();

    let offset = 0
    switch (timeZone) {
        case "none": break;
        case "pst": offset = -2 * 60 * 60 * 1000; break;
        case "est": offset = -5 * 60 * 60 * 1000; break;
        default: break; 
    }
    return new Date(currentTime.getTime() + offset);
}

/**
 * Determines if there is overlap between a time frame and the current time
 *  
 * @param start military time of the time frame
 * @param duration in milliseconds of the time frame
 * @returns true if time frame (time + duration) overlaps the time right now
 */
export function doesTimeFrameOverlapNow(start: string, duration: number): boolean {
    const currentTime = new Date();
    const day = currentTime.getDate();
    const month = currentTime.getMonth();
    const year = currentTime.getFullYear();

    const currentTimeEST = getTimeNow();
    
    const hr = parseInt(start.substring(0, 2));
    const min = parseInt(start.substring(2, 4));

    const timeUTC = new Date(Date.UTC(year, month, day, hr, min));

    const endTimeUTC = new Date(timeUTC.getTime() + duration);
    return currentTimeEST >= timeUTC && currentTimeEST <= endTimeUTC;
}

/**
 * Extracts the playFrom object from the nowPlaying response object
 * Handles both play from Playlist and Schedule
 * 
 * @param nowPlaying SYB api response
 * @param zone to fetch information from
 * @param updatePlaylist function that handles updating of playlist state object
 * @param sidebarSpotify list of sidebar spotify playlists' metadata
 * @param scheduleSpotify list of schedule's spotify playlists' metadata
 * @param getScheduledPlaylist function that determines which schedule playlist is currently playing
 * @returns 
 */
export async function extractPlayFrom(
    nowPlaying: any, 
    zone: newZoneType, 
    updatePlaylist: CallableFunction, 
    sidebarSpotify: spotifyPlaylist[],
    scheduleSpotify: spotifyPlaylist[],
    getScheduledPlaylist: CallableFunction
): Promise<newPlayFromType> {
    const playFrom: newPlayFromType = {};

    switch (nowPlaying.playFrom.__typename) {
        case "Playlist":
            playFrom.playlist = {
                id: nowPlaying.playFrom.id,
                name: nowPlaying.playFrom.name,
            }
            let spotifyPlaylist = findPlaylist(undefined, undefined, playFrom.playlist.name, sidebarSpotify) as spotifyPlaylist;
            if (!spotifyPlaylist) {
                spotifyPlaylist = findPlaylist(undefined, undefined, playFrom.playlist.name, scheduleSpotify) as spotifyPlaylist;
                if (spotifyPlaylist) {
                    playFrom.playlist = undefined;
                    playFrom.schedule = {
                        id: nowPlaying.playFrom.id,
                        playlist: {
                            id: nowPlaying.playFrom.id,
                            name: nowPlaying.playFrom.name
                        },
                    }
                    break;
                }
            }
            
            if (spotifyPlaylist) await updatePlaylist(spotifyPlaylist.uri);

            break;
        case "Schedule":
            const schedule = (await getScheduledPlaylist({id: zone?.scheduleId})).data.schedule;

            const id = findScheduledPlaylist(schedule);
            const scheduledPlaylist = schedule.playlists.find((playlist: any) => playlist.id === id);
            if (scheduledPlaylist) {
                await updatePlaylist(id, scheduledPlaylist?.name);
            } 

            playFrom.schedule = {
                id: nowPlaying.playFrom.id,
                playlist: {
                    id: scheduledPlaylist?.id,
                    name: scheduledPlaylist?.name
                },
            }
            break;
    }

    return playFrom;
}

/**
 * 
 * @param uri 
 * @param zone 
 * @param syb 
 * @param spotifyAPI 
 * @param global 
 * @param spotify 
 * @param notification 
 * @param loadSpotifyPlaylist 
 * @returns 
 */
export async function updateSidebarPlaylist(
    uri: string, 
    zone: newZoneType, 
    syb: sybPlaylist[], 
    spotifyAPI: Spotify, 
    global: LoadingContextType, 
    spotify: spotifyPlaylist[], 
    notification: NotificationContextType, 
    loadSpotifyPlaylist: CallableFunction
): Promise<newPlaylistDataType | undefined> {
    let selectedPlaylist = findPlaylist(undefined, uri, undefined, spotify) as spotifyPlaylist;

    if (!selectedPlaylist?.tracks) {
        global?.setLoading({load: true, message: `Loading ${selectedPlaylist.name || "playlist"}...`});
        const spotifyPlaylist = await spotifyAPI.loadPlaylist(uri.split(":")[2]);
        if (!spotifyPlaylist) {
            notification?.setNotification({value: "Failed to find spotify playlist", notify: true});
            global?.setLoading({load: false, message: ""});
            return;
        }
        const sybPlaylist = await loadSYBPlaylistData(loadSpotifyPlaylist, zone, spotifyPlaylist.tracks?.length || 0, undefined, spotifyPlaylist.uri);
        global?.setLoading({load: false, message: ""});

        return {
            spotify: spotify.map((playlist) => playlist.uri === uri? spotifyPlaylist : playlist),
            syb: syb.map((playlist, i) => spotify[i].uri === uri? sybPlaylist : playlist),
        }
    }
}

/**
 * Finds which track is currently playing and load's its metadata into the nowPlaying state object
 * Uses the name of the track and the playlist it belongs to in order to determine which track it is
 * WARNING: DOES NOT YET HANDLE CONFLICTS (ASSUMES PLAYLIST NAME AND TRACK ARE UNIQUE)
 * TODO: implement a better handling of conflicts, right now first matching track is returned
 * Assumes playlists and playFrom are defined 
 * 
 * @param playlists that contain the sidebar and schedule syb and spotify metadata
 * @param playFrom state containing information about where the current track is playing from
 * @param trackName to look for
 * @param getIndex if defined indicates which index to return, if undefined we return the currently playing track obj
 * @param type whether to fetch syb or spotify track
 * @returns 
 */
export function findNowPlayingTrack(playlists: newPlaylistsType, playFrom: newPlayFromType, trackName: string, getIndex?: "current" | "next" | "prev" | "first" | "last", type?: "spotify" | "syb", filterExplicit: boolean = true): spotifyTrackType | undefined | number | sybTrackType {
    if (!playlists || !playFrom) return;
    
    let syb: sybPlaylist[];
    let playlistName: string;
    let spotify: spotifyPlaylist[];

    if (playFrom.playlist) {
        syb = playlists.sidebar.syb;
        playlistName = playFrom.playlist?.name;
        spotify = playlists.sidebar.spotify;
    } else if (playFrom.schedule) {
        syb = playlists.schedule.syb;
        spotify = playlists.schedule.spotify;
        playlistName = playFrom.schedule.playlist?.name;
    } else return;

    const sybPlaylist = findPlaylist(undefined, undefined, playlistName, undefined, syb) as sybPlaylist;

    let tracks: sybTrackType[] | spotifyTrackType[] | undefined;
    switch (type) {
        case "syb":
            tracks = sybPlaylist?.tracks;
            break;
        default:
            tracks = spotify.find((playlist) => playlist.name === playlistName)?.tracks;
            break;
    } 
    if (tracks) return findNowPlayingHelper(sybPlaylist, tracks, trackName, getIndex, filterExplicit);
}

/** Refer to findNowPlaying for docstring */
export function findNowPlayingHelper(sybPlaylist: sybPlaylist, tracks: spotifyTrackType[] | sybTrackType[], trackName: string, getIndex?: "current" | "next" | "prev" | "first" | "last", filterExplicit: boolean = true): spotifyTrackType | undefined | number | sybTrackType {
    if (getIndex === "first") return tracks?.findIndex((track, i: number) => sybPlaylist?.tracks[i].isAvailable && ((filterExplicit && !track.explicit) || !filterExplicit));
    if (getIndex === "last") return tracks?.findIndex((track, i: number) => sybPlaylist?.tracks[i].isAvailable && ((filterExplicit && !track.explicit) || !filterExplicit));

    const currentIndex = tracks?.findIndex((_, i: number) => sybPlaylist?.tracks? sybPlaylist?.tracks[i].title === trackName : false);
    if (currentIndex > -1) {
        switch (getIndex) {
            case "current": return currentIndex;
            case "next": return tracks?.findIndex((track, i: number) => i > currentIndex && sybPlaylist?.tracks[i].isAvailable && ((filterExplicit && !track.explicit) || !filterExplicit));
            case "prev": return currentIndex - 1 - tracks?.slice(0, currentIndex).reverse().findIndex((track, i: number) => i < currentIndex && sybPlaylist?.tracks[currentIndex - 1 - i].isAvailable && ((filterExplicit && !track.explicit) || !filterExplicit));
            default: return tracks[currentIndex]
        }
    }
}

/**
 * Controls the displays of a set of elements at once
 * Invariant: elmIds.length === displays.length
 *                
 * @param elmIds the ids of the elements to be modified
 * @param displays the values to set each element's display to
 */
export function setDisplays(elmIds: string[], displays: ("flex" | "none")[]) {
    for (let i = 0; i < elmIds.length; i++) {
        const id = elmIds[i];
        const elm = document.getElementById(id);
        if (elm) elm.style.display = displays[i];
    }
}

/**
 * Extracts the info from the saved schedule object
 * 
 * @param loadedSchedules the schedules loaded from zoneMetaData
 * @returns a list of ariaScheduleTypes
 */
export function toAriaSchedule(loadedSchedules: any): ariaScheduleType[] | undefined {
    const schedules: ariaScheduleType[] = [];
    if (!loadedSchedules) return;
    
    for (const loadedSchedule of loadedSchedules) {
        schedules.push({
            day: loadedSchedule.day,
            breaks: loadedSchedule.breaks,
            rrule: loadedSchedule.rrule,
            ariaSlots: loadedSchedule.buckets.map((bucket: any) => ({
                end: bucket.schedule.end,
                start: bucket.schedule.start,
                playlists: bucket.schedule.uris?.map((uri: string, i: number) => ({
                    spotifyUri: uri,
                    name: bucket.schedule.names[i],
                    cover: bucket.schedule.imgSrcs[i]
                })),
                spotifyId: bucket.schedule.id !== ""? bucket.schedule.id : undefined
            }))
        })
    }

    // console.log("[toAriaSchedule] (schedules) >>", schedules)

    return schedules;
}

/**
 * Wraps functions to collapse / un-collapse on resizing of window 
 * 
 * @param callback function expected to be run
 * @param setCollapse hook to handle changing collapse state 
 * @param width of the current window
 * @param orientation portrait or landscape
 * @returns a callback to first execute the callback then un-=-collapse
 */
export function dynamicResizeCallbackWrapper(callback: CallableFunction, setCollapse: CallableFunction, width?: number, orientation?: string) {
    return (args: any) => {
        callback(args);
        if (!resize(width, orientation)) setCollapse(true)
    }
}

/***
 * Checks if website should be dynamically resized to width > widthBreakpoint
 * @returns true if width > widthBreakpoint and orientation is landscape else false
 */
export function resize(width: number | undefined, orientation: string | undefined): boolean {
    if (width && orientation) return width > 1000 && (isMobile? orientation.includes("landscape") : true);
    return false;
}

export function generateRandomId(n: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const c = characters.length;

    let randomId = ""
    for (let i = 0; i < n; i++) {
        randomId += characters.charAt(Math.floor(Math.random() * c));
    }
    return randomId;
}

export function addToLocalStorage(key: string, value: string) {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        clearLocalStorage();
        localStorage.setItem(key, value);
    }
}

export function clearLocalStorage() {
    const permanent = ["sybSchedule", "nowPlaying", "currentLocationId"];
    const n = localStorage.length;

    for (let i = 0; i < n; i++) {
        const key = localStorage.key(i);
        if (!key) continue;
        if (permanent.includes(key)) continue;
        localStorage.removeItem(key);
    }
}

export function computeRemainingSlotDuration(slot: sybSlotType): number {
    const ms = slot.duration;
    const min = Math.floor(ms / 1000 / 60);
    const start = `${slot.start.slice(0,2)}:${slot.start.slice(2,4)}`;

    const end = computeFutureTime(start, min);
    // console.log("[utils][computeRemainingSlotDuration] (end) >>", end);
    const now = getTimeNow("none");

    return computeDuration(`${now.getHours()}:${now.getMinutes()}`, end) * 60 * 1000;
}

export function getNextTracks(start: number, scheduledPlaylist: sybPlaylist, scheduledSlot: sybSlotType | undefined): string[] {
    if (!scheduledPlaylist || !scheduledSlot) return [];

    const nextTracks = []
    if (start >= scheduledPlaylist.tracks.length) start = -1;
    
    let duration = 0;
    // console.log("[utils][getNextTracks] (scheduledSlot) >>", scheduledSlot);

    const durationLeft = computeRemainingSlotDuration(scheduledSlot);
    console.log("[utils][getNextTracks] (durationLeft) >>", durationLeft);

    if (durationLeft > 0) {
        for (let j = start; j < scheduledPlaylist.tracks.length; j++) {
            const track = scheduledPlaylist.tracks[j];
            if (track.id) {
                if (duration + track.durationMs < durationLeft) {
                    nextTracks.push(track.id);
                    duration += track.durationMs;
                } else break;
            }
        }
    } 

    return nextTracks;
}

export function clearCookies() {
    console.log("[clearCookies] (cookies) >>", document.cookie)
    document.cookie.split(";").forEach((c) => {document.cookie = c + "=;expires=" + new Date().toUTCString();});
}

export function getDevOrDepUrl(urlToGet: "syb" | "location" | "zone" | "aria" | "admin") {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        switch (urlToGet) {
            case "syb": return "https://ariasound.live/api-proxy/"
            case "location": return "/location"
            case "admin": return "/admin"
            case "zone": return "/zone"
            case "aria": return "/"
        }
    } else if (window.location.hostname === "fabhous.com") {
        if (localStorage.getItem("fabAuthenticatedFor") !== "aria") {
            window.location.href = "https://fabhous.com"; 
            return;
        }
        switch (urlToGet) {
            case "syb": return `https://${window.location.hostname}/aria/api-proxy/`
            case "location": return "/aria/location"
            case "zone": return "/aria/zone"
            case "admin": return "/aria/admin"
            case "aria": return "/aria"
        }
    } else {
        switch (urlToGet) {
            case "syb": return `https://${window.location.hostname}/api-proxy/`
            case "location": return "/location"
            case "admin": return "/admin"
            case "zone": return "/zone"
            case "aria": return "/"
        }
    }
}

export function objArrayToCSV(objArray: any[]): string {
    const headers = [
        "date",
        "playBackTime",
        
        "sybZone",
        "sybLocation",
        
        "playlist",
        "artists",
        "songTitle",
        "genres",
        
        "energy",
        "valence",
        "danceability",
        "tempo",
        "mode",
        "instrumentalness",
        "loudness",
        
        "sybVolume",
        
        // Meta data
        "userUID",
        "sybAccount",
        "userEmail",
        "sybTrackURI",
        "spotifyTrackURI"
    ]
    let csv = headers.join(",") + "\n";

    for (const obj of objArray) {
        for (const header of headers) {
            const field = obj[header]
            if (field instanceof Object) {
                csv += `${objToString(field)},`
            } else if (field instanceof Array) {
                csv += `${arrayToString(field)},`
            } else if (field === undefined || field === null) {
                csv += `N/A,` 
            } else csv += `${field},` 
        }
        csv += "\n"
    }
    return csv
}

export function objToString(obj: any) {
    let objString = "{";

    for (const [key, field] of Object.entries(obj)) {
        if (field instanceof Object) {
            objString += `(${key} => ${objToString(field)}) `
        } else if (field instanceof Array) {
            objString += `(${key} => ${arrayToString(field)}) `
        } else if (field === undefined || field === null) {
            objString += ` ` 
        } else objString += `(${key} => ${field})` 
    }

    return objString + "}"
}

export function arrayToString(array: any[]) {
    let arrayString = "[";

    for (const field of array) {
        if (field instanceof Object) {
            arrayString += `${objToString(field)} `
        } else if (field instanceof Array) {
            arrayString += `${arrayToString(field)} `
        } else if (field === undefined || field === null) {
            arrayString += ` ` 
        } else arrayString += `${field} ` 
    }

    return arrayString + "]"
}