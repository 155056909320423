// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ariaSchedulesType, ariaVibesType, newPlaylistsType, spotifyPlaylist, userType } from "../types";
import { nullify } from "../utils";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5rE_Jo6rTe3LZzTkiqcxPmtOfDTRtOOY",
  authDomain: "aria-e69fa.firebaseapp.com",
  projectId: "aria-e69fa",
  storageBucket: "aria-e69fa.appspot.com",
  messagingSenderId: "793569322275",
  appId: "1:793569322275:web:911ce5989f6d4ba2f75896",
  measurementId: "G-RFGVHV1ZNQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);

export { db };

// ensure id field is present in spotify metadata
interface SoundZoneMetadata {
  // Deprecated
  scheduleInfo?: any,
  
  playlists?: {
    sidebar: {spotify: spotifyPlaylist[]}, 
    schedule: {spotify: spotifyPlaylist[]}
  }
  ariaSchedule?: any,
  ariaVibes?: any,
  volume?: number
}

/**
 * Stores the metadata in the soundZone firebase document 
 * 
 * @param soundZoneId key of the document to store
 * @param metadata metadata to store
 * @param merge if true indicates we should add to existing document, if false overwrite
 * @returns true if save was successful, else false
 */
export async function saveSoundZoneMetadata(soundZoneId : string, metadata : SoundZoneMetadata, merge: boolean = false) : Promise<boolean> {
  const zoneDocRef = doc(db, "soundZoneMetadata", soundZoneId);
  try {
    // console.log("[saveSoundZoneMetadata] saving (metadata) >>", metadata) 
    await setDoc(zoneDocRef, metadata, { merge: merge });
  } catch (e) {
    console.error(e)
    return false;
  }
  return true;
}

/**
 * Loads sound zone metadata if it is currently stored
 * 
 * @param soundZoneId of the zone associated with these schedules
 * @returns sound zone metadata for a given zone Id, or undefined if zone not found. 
 */
export async function getSoundZoneMetadata(soundZoneId: string, i: number = 0): Promise<SoundZoneMetadata | undefined> {
  const soundZoneDocRef = doc(db, "soundZoneMetadata", soundZoneId);
  const soundZoneDocSnap = await getDoc(soundZoneDocRef);
  if (soundZoneDocSnap.exists()) {
    const data = soundZoneDocSnap.data() as SoundZoneMetadata;
    if (i < 10 && !data.playlists) return await getSoundZoneMetadata(soundZoneId, i + 1);
    return data;
  } else return;
}

/**
 * Stores the playlists state object in the soundZone firebase document to speed up first load
 * 
 * @param soundZoneId of the zone associated with these schedules
 * @param playlists the playlists state object
 * @param merge if true indicates we should add to existing document, if false overwrite
 * @returns true if save was successful, else false
 */
export async function savePlaylists(soundZoneId: string, playlists: newPlaylistsType, merge: boolean = true): Promise<boolean> {
  const sidebar = {
    spotify: playlists.sidebar.spotify.map(playlist => ({...playlist, tracks: []})),
  }
  const schedule = {
    spotify: playlists.schedule.spotify.map(playlist => ({...playlist, tracks: []})),
  }

  return saveSoundZoneMetadata(soundZoneId, {playlists: {
    sidebar: sidebar,
    schedule: schedule
  }}, merge)
}

/**
 * Stores the Aria schedules as a JSON object
 * 
 * @param soundZoneId of the zone associated with these schedules
 * @param schedules the Aria schedules to be saved
 * @param merge if true indicates we should add to existing document, if false overwrite
 * @returns true if save was successful, else false
 */
export async function saveAriaSchedules(soundZoneId: string, schedules: ariaSchedulesType, merge: boolean = true): Promise<boolean> {
  return saveSoundZoneMetadata(soundZoneId, {ariaSchedule: nullify(schedules)}, merge)
}

/**
 * Stores the Aria vibes as a JSON object
 * 
 * @param soundZoneId of the zone associated with these schedules
 * @param vibes the Aria vibes to be saved
 * @param merge if true indicates we should add to existing document, if false overwrite
 * @returns true if save was successful, else false
 */
export async function saveAriaVibes(soundZoneId: string, vibes: ariaVibesType, merge: boolean = true): Promise<boolean> {
  return saveSoundZoneMetadata(soundZoneId, {ariaVibes: nullify(vibes)}, merge)
}

/**
 * Stores the Aria volume 
 * 
 * @param soundZoneId of the zone associated with these schedules
 * @param volume the Aria to be stored (an int between 1 and 16)
 * @param merge if true indicates we should add to existing document, if false overwrite
 * @returns true if save was successful, else false
 */
export async function saveSYBZoneVolume(soundZoneId: string, volume: number, merge: boolean = true): Promise<boolean> {
  // console.log("[firebase][saveSYBZoneVolume] (volume) >>", volume);
  return saveSoundZoneMetadata(soundZoneId, {volume: volume}, merge)
}

/**
 * Stores spotify playlist information to avoid Spotify too many requests error
 *  
 * @param uri of the spotify playlist to store information of
 * @param playlist containing spotify metadata to store
 * @returns true if save was successful, else false
 */
export async function saveSpotifyPlaylist(uri: string, playlist: spotifyPlaylist): Promise<boolean> {
  const playlistDocRef = doc(db, "tracks", uri);
  try {
    // console.log(`[saveSpotifyPlaylist] saving >>`, nullify(playlist))
    await setDoc(playlistDocRef, nullify(playlist));
  } catch (error) {
    console.error("[saveSpotifyPlaylist] (error) >>", error)
    return false;
  }
  return true;
}

/**
 * Loads spotify playlist metadata if it is currently stored
 * 
 * @param uri of the spotify playlist to store information of
 * @returns the spotify if it exists, else undefined
 */
export async function getSpotifyPlaylist(uri: string): Promise<spotifyPlaylist | undefined> {
  const playlistDocRef = doc(db, "tracks", uri);
  const playlistDocSnap = await getDoc(playlistDocRef);
  if (playlistDocSnap.exists()) 
    return playlistDocSnap.data() as spotifyPlaylist;
  else 
    return;
}

export async function getUser(id: string): Promise<userType | undefined> {
  const userDocRef = doc(db, "users", id);

  const userDocSnap = await getDoc(userDocRef)

  if (userDocSnap.exists()) {
    return userDocSnap.data() as userType 
  } 
}